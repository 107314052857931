import { OTAService } from '@/core/services/ota'
import { addSentryBreadCrumb } from '@/utils/sentry'

type GetAuthMeFetcherProps = {
  url: string
}

export const getAuthMeFetcher = async ({}: GetAuthMeFetcherProps) => {
  const response = await OTAService.getAuthMe()

  addSentryBreadCrumb({ breadCrumbName: 'fetch: OTAService.getAuthMe()', object: response || {} })

  return response
}