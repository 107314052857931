import * as Sentry from '@sentry/nextjs'

type CaputerSentryMessageProps = {
  breadCrumbName: string
  object: Record<string, any> // eslint-disable-line
}
export const addSentryBreadCrumb = ({ breadCrumbName, object }: CaputerSentryMessageProps) => {
  try {
    Sentry.addBreadcrumb({
      level: 'log',
      category: breadCrumbName,
      type: 'User Interaction',
      message: breadCrumbName,
      data: object
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}
