import useSWR from 'swr'

import { getAuthMeFetcher } from '@/functions/fetchers/getAuthMe'
import { shouldFetchOTA } from '@/hooks/utils/shouldFetchOTA'

export function useGetAuthMe() {
  const { data, error, isLoading } = useSWR(shouldFetchOTA() ? { url: 'getAuthMe' } : null, getAuthMeFetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false
  })

  const loading = isLoading || (!data && !error)

  return {
    loading,
    user: data,
    error
  }
}
