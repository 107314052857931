import { BodyType, ResponseType } from '@/functions/chat/updateChat'
import { addSentryBreadCrumb } from '@/utils/sentry'
import * as Sentry from '@sentry/nextjs'

type UpdateChatProps = {
  arg: BodyType
}

export const updateChatFetcher = async (key: string, { arg }: UpdateChatProps): Promise<ResponseType | undefined> => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(arg)
    }

    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL!}/update-chat`, requestOptions)
    const data = await response.json()

    addSentryBreadCrumb({ breadCrumbName: 'fetch: updateChat()', object: data })

    if (response.status !== 200) {
      throw new Error(data.message)
    }

    return data
  } catch (error) {
    if (error instanceof Error) {
      Sentry.captureException({ error })
      throw new Error(error.message)
    }
  }
}
