import { User } from '@/core/services/types/getAuthMe/responseTypes'
import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { useGetAuthMe } from '@/hooks/data/ota/useGetAuthMe'
import { getCookie } from '@/utils/cookies'
import { OTATokenCookie } from '@/enums/cookies'
import * as Sentry from '@sentry/nextjs'
import { ChatEntityType } from '@/core/dynamodb'
import { useUpdateChat } from '@/hooks/data/useUpdateChat'
import { storageKeys } from '@/enums/localStorage'

type UseSetUserProps = {
  chat?: ChatEntityType
}

export const useSetUser = ({ chat }: UseSetUserProps = {}) => {
  const { user } = useGetAuthMe()
  const { updateChat } = useUpdateChat()

  const [storedUser, setStoredUser] = useLocalStorage<Omit<User, 'paymentContracts'> | null>(storageKeys.user, null)

  const chatId = chat?.chatId
  const chatUserId = chat?.userId

  useEffect(() => {
    const setUser = async () => {
      const hasIsLoggedInCookie = !!getCookie(OTATokenCookie)

      if (hasIsLoggedInCookie && user) {
        if (!storedUser) {
          setStoredUser(user)
        }

        // Sentry is not persistent. So always set to Sentry
        Sentry.setUser({
          id: user._id,
          email: user.email,
          username: user.firstName,
          segment: user.country.name,
          status: user.status
        })

        if (chatId && !chatUserId) {
          await updateChat({ chatId, type: 'support-chat', userEmail: user.email, userId: `${user._id}` })
        }

        return
      }
    }

    setUser()
  }, [user, storedUser, setStoredUser, chatId, chatUserId, updateChat])
}
