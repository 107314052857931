import { OTATokenCookie } from '@/enums/cookies'
import { getCookie } from '@/utils/cookies'
import { GraphQLClient, gql } from 'graphql-request'
import { GetAuthMeData } from '@/core/services/types/getAuthMe/responseTypes'
import { extractMembers, extractOrderBundles, extractPaymentContracts } from '@/utils/ota/data'
import { GetMembersData } from '@/core/services/types/getMembers/responseTypes'
import { OrderBundlesDataType } from '@/core/services/types/getOrderBundles/responseTypes'
import { GetLoyaltyPointsDataType } from '@/core/services/types/getLoyaltyPoints/responseTypes'

import * as Sentry from '@sentry/nextjs'

export * as OTAService from './ota'

const endpoint = `${process.env.NEXT_PUBLIC_MAIN_WEBSITE_BASEURL}/api/graphql`

const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    authorization: `Bearer ${getCookie(OTATokenCookie)}`
  },
  cache: 'no-cache'
})

export const getAuthMe = async () => {
  try {
    const query = gql`
      {
        meAuthUser {
          user {
            _id
            firstName
            email
            status
            paymentContracts {
              edges {
                node {
                  status
                  active
                }
              }
            }
            country {
              _id
              name
              canCancelSubscriptionAfterDays
              expectedDeliveryDateDailyOrderAfterDays
              canDoReturnRequestAfterDay
            }
          }
        }
      }
    `
    const data = await graphQLClient.request<GetAuthMeData>(query)

    const { user } = data.meAuthUser

    return {
      ...user,
      paymentContracts: extractPaymentContracts(user.paymentContracts.edges)
    }
  } catch (error) {
    if (error instanceof Error) {
      Sentry.captureException({ error })
      throw new Error(error.message)
    }
  }
}

export type GetMembersProps = {
  includeAddress?: boolean
  count?: number
}

export const getMembers = async ({ includeAddress = true, count = 30 }: GetMembersProps = {}) => {
  try {
    const variables = {
      includeAddress,
      first: count
    }

    const query = gql`
      query Members($includeAddress: Boolean = false, $first: Int = 30) {
        members(first: $first, order: { createdAt: "ASC" }) {
          edges {
            node {
              _id
              fullName
              email
              status
              pauseDate
              unPauseDate
              subscriptionDate
              stopMembershipAt
              postalAddress @include(if: $includeAddress) {
                _id
                street
                houseNumber
                annex
                postalCode
                city
                building
                floor
                streetType
                region
                fullStreet
                country {
                  _id
                  hostNamePrefix
                }
              }
            }
          }
        }
      }
    `
    const { members } = await graphQLClient.request<GetMembersData>(query, variables)

    return extractMembers({ members })
  } catch (error) {
    if (error instanceof Error) {
      Sentry.captureException({ error })
      throw new Error(error.message)
    }
  }
}

export type GetOrderBundlesProps = {
  limit?: number
  after?: string | null
}

export const getOrderBundles = async ({ limit = 10, after = null }: GetOrderBundlesProps = {}) => {
  try {
    const variables = {
      limit,
      after
    }

    const query = gql`
      query OrderBundles($limit: Int = 10, $after: String = null) {
        orderBundles(
          first: $limit
          after: $after
          order: { createdAt: "DESC" }
          orders_status_list: [0, 1, 3, 4, 5, 6, 7]
          older_than_createdAt: "2 days ago"
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
          edges {
            node {
              _id
              amount
              createdAt
              payment {
                id
                _id
                status
                debitDate
                currencyIsoCode
                amount
                requested
                refundDate
              }
              orders {
                edges {
                  node {
                    _id
                    id
                    amount
                    type
                    status
                    createdAt
                    isSpecial
                    campaign {
                      _id
                      orderTriggerDate
                    }
                    returnOrders {
                      edges {
                        node {
                          id
                          description
                          status
                          reason
                          createdAt
                        }
                      }
                    }
                    product {
                      id
                      name
                      productImages
                      productGroupSize {
                        _id
                        id
                        size {
                          _id
                          id
                          label
                          extraLabel
                        }
                        productGroup {
                          _id
                          id
                          name
                          displayName
                        }
                      }
                      design {
                        name
                        primaryColor
                        secondaryColor
                        release {
                          expectedDeliveryDate
                          releaseDate
                        }
                      }
                    }
                    returnOrders {
                      edges {
                        node {
                          id
                          description
                          status
                          reason
                          createdAt
                        }
                      }
                    }
                    shipment {
                      id
                      deliveryDate
                      status
                    }
                    member {
                      _id
                      id
                      firstName
                      lastName
                      fullName
                      country {
                        name
                        vat {
                          value
                        }
                      }
                      postalAddress {
                        street
                        houseNumber
                        postalCode
                        city
                        annex
                        building
                        floor
                        streetType
                        region
                        country {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
    const data = await graphQLClient.request<OrderBundlesDataType>(query, variables)

    return {
      pageInfo: data.orderBundles.pageInfo,
      orderBundles: extractOrderBundles(data.orderBundles.edges)
    }
  } catch (error) {
    if (error instanceof Error) {
      Sentry.captureException({ error })
      throw new Error(error.message)
    }
  }
}

export const getLoyaltyPoints = async () => {
  try {
    const query = gql`
      query spaazaWallet {
        getSpaazaWallet {
          amount
          currency
        }
      }
    `

    const data = await graphQLClient.request<GetLoyaltyPointsDataType>(query)

    return data
  } catch (error) {
    if (error instanceof Error) {
      Sentry.captureException({ error })
      throw new Error(error.message)
    }
  }
}
