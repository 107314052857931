import { PaymentContract } from '@/core/services/types/getAuthMe/responseTypes'
import { convertSrcSet } from '@/utils/ui'
import { NewOrderBundle } from '@/enums/general'
import { MemberEdge } from '@/core/services/types/getMembers/responseTypes'
import { OrderBundleEdge } from '@/core/services/types/getOrderBundles/responseTypes'

export type ExtractOrdersReturnType = NewOrderBundle[]

export const extractOrderBundles = (edges: OrderBundleEdge[]): ExtractOrdersReturnType => {
  return edges.map((edge) => {
    const deepestOrderNode = edge.node.orders.edges.map((e) => ({
      ...e.node,
      product: {
        ...e.node.product,
        productImages: convertSrcSet({ images: e.node.product.productImages })
      }
    }))

    return {
      ...edge.node,
      orders: deepestOrderNode
    }
  })
}

type PaymentContractEdges = {
  node: PaymentContract
}

export const extractPaymentContracts = (edges: PaymentContractEdges[]): PaymentContract[] => {
  const paymentContracts: PaymentContract[] = []

  for (const edge of edges) {
    paymentContracts.push(edge.node)
  }

  return paymentContracts
}

export type ExtractMembersProps = {
  members: {
    edges: MemberEdge[]
  }
}

export const extractMembers = ({ members }: ExtractMembersProps) => {
  return members.edges.map((member) => {
    return member.node
  })
}
