import useSWRMutation from 'swr/mutation'

import { updateChatFetcher } from '@/functions/fetchers/updateChat'

export function useUpdateChat() {
  const { trigger, error, isMutating } = useSWRMutation('updateChat', updateChatFetcher, { revalidate: false })

  const loading = isMutating && !error

  return {
    loading,
    updateChat: trigger,
    error
  }
}
